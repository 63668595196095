import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import DetailPageV2 from "../../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
import UserContext from '../../../user/user-context';
import UploadStatementModal from "./upload-statement-modal";

import * as s from '../../../utilities/services/payment-method-service'

const PaymentMethodBankDetailPage = () => {

    const user = useContext(UserContext)
    const { id } = useParams()

    const [showModal,setShowModal] = useState(false)
 
    configs.c_actions = [
        {
            label: 'Statement',
            handler: () => setShowModal(true)
        }
    ]

    return (
        <DetailPageV2 service={s} configs={configs}>
            {
                configs.actions.statement && showModal && 
                <UploadStatementModal item={{id}} close={()=> setShowModal(false)} user={user}/>
            }
        </DetailPageV2>
    )
}

export default PaymentMethodBankDetailPage